import React from 'react';
import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton";

const Header = () => {
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar gameon-navbar navbar-expand">
                <div className="container header">
                    {/* Logo */}
                    <a className="navbar-brand" href="/">
                        <img src="/img/hive-protocol.png" alt="Hice Protocol" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar Nav */}
                    <ul className="navbar-nav items">
                        <li className="nav-item">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        {/*<li className="nav-item dropdown">
                            <a href="#" className="nav-link">Projects <i className="icon-arrow-down" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href="/project-one" className="nav-link">Project Style 1</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-two" className="nav-link">Project Style 2</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-three" className="nav-link">Project Style 3</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-four" className="nav-link">Project Style 4</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-single" className="nav-link">Project Single</a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link">Staking <i className="icon-arrow-down" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href="/staking-one" className="nav-link">Staking Style 1</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/staking-two" className="nav-link">Staking Style 2</a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link">Pages <i className="icon-arrow-down" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href="/farming" className="nav-link">Farming</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/leaderboard" className="nav-link">Leaderboard</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link">Inner Pages <i className="icon-arrow-right" /></a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <a href="/apply" className="nav-link">Apply for Project</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/wallet-connect" className="nav-link">Wallet Connect</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/help-center" className="nav-link">Help Center</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/contact" className="nav-link">Contact</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link">Auth Pages <i className="icon-arrow-right" /></a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <a href="/login" className="nav-link">Login</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/register" className="nav-link">Register</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/reset" className="nav-link">Forgot Password</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a href="/tokenomics" className="nav-link">Tokenomics</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/tier-system" className="nav-link">Tier System</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link">Community <i className="icon-arrow-right" /></a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <a href="/blog" className="nav-link">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/blog-single" className="nav-link">Blog Single</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        */}
                        <li className="nav-item">
                            <a href="#explore" className="nav-link smooth-anchor">Ecosystem</a>
                        </li>
                        <li className="nav-item">
                            <a href="#cells" className="nav-link smooth-anchor">Cells</a>
                        </li>
                        <li className="nav-item">
                            <a href="#technology" className="nav-link smooth-anchor">Technology</a>
                        </li>
                        <li className="nav-item">
                            <a href="/hip-cell" className="nav-link">HIP Cell</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://litepaper.hiveprotocol.ai/" className="nav-link" target="_blank">Litepaper</a>
                        </li>
                        <li className="nav-item pl-3">
                            <a href="https://twitter.com/Hip_Protocol" className="nav-link social-link" target="_blank"><img src="/img/x.png" className="social-icon" /></a>
                        </li>
                        <li className="nav-item">
                            <a href="https://t.me/+7OkhGKzSkRcyMDgx" className="nav-link social-link" target="_blank"><img src="/img/telegram.png" className="social-icon" /></a>
                        </li>
                        <li className="nav-item">
                            <a href="https://dexscreener.com/base/0x4c39e7900db55b094fb13918eb4066a1c5412def" className="nav-link social-link" target="_blank"><img src="/img/dexscreener.png" className="social-icon" /></a>
                        </li>
                        <li className="nav-item pr-3">
                            <a href="https://www.dextools.io/app/en/base/pair-explorer/0x4c39e7900db55b094fb13918eb4066a1c5412def" className="nav-link social-link" target="_blank"><img src="/img/dextools.png" className="social-icon" /></a>
                        </li>
                        {/*
                        <li className="nav-item">
                            <a href="/contact" className="nav-link">Contact</a>
                        </li>
                        */}
                    </ul>
                    {/* Navbar Icons */}
                    {/*
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#search">
                                <i className="icon-magnifier" />
                            </a>
                        </li>
                    </ul>
                    */}
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="icon-menu m-0" />
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Action Button */}
                    <ul className="navbar-nav action">
                        <li className="nav-item ml-2">
                            <ConnectWalletButton />
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;