import React, { Component } from 'react';
import axios from 'axios';

class Content extends Component {
    state = {
        data: {},
        contentData: []
    }
    componentDidMount() {
        const jsonData = 
        {
            "sub_heading": "Volatility Arbitrage",
            "heading": "Discover The Ecosystem",
            "excerpt": "Anyone can gain access to a cell. When users supply liquidity to a cell with their wrapped tokens (cHIP) they are reinforcing the stability of the fund thereby stimulating the Hive ecosystem so that all holders can benefit from real yield. The greater the investment the greater the rewards.",
            "contentData": [
                {
                    "id": 1,
                    "icon": "fa-solid fa-money-bill-1-wave",
                    "featured": "",
                    "title": "Buy HIP",
                    "content": "Buy HIP on your favourite DEX. BASE contract: <span class=\"d-none d-md-block\"><a href=\"https://basescan.org/address/0x0000000000000000000000000000000000000000\" target=\"_blank\">0x0000000000000000000000000000000000000000</a></span><div class=\"d-md-none d-lg-none\"><a href=\"https://basescan.org/address/0x0000000000000000000000000000000000000000\" target=\"_blank\">0x0000...0000</a></div>"
                },
                {
                    "id": 2,
                    "icon": "fa-solid fa-gift",
                    "featured": "",
                    "title": "Wrap HIP (cHIP)",
                    "content": "Gain access to the Hive ecosystem by wrapping your HIP to cHIP tokens, using our dApp."
                },
                {
                    "id": 3,
                    "icon": "fa-solid fa-clock",
                    "featured": "featured",
                    "title": "Earn & Harvest",
                    "content": "Supply liquidity to a cell with your cHIP and earn real yield. Harvest your rewards at any time."
                }
            ]
        };

        this.setState({
            data: jsonData,
            contentData: jsonData.contentData
        });
    }
    render() {
        return (
            <section className="content-area" id="explore">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="content intro">
                            <span className="intro-text">{this.state.data.sub_heading}</span>
                            <h2>{this.state.data.heading}</h2>
                            <p>{this.state.data.excerpt}</p>
                            <ul className="list-unstyled items mt-5">
                                {this.state.contentData.map((item, idx) => {
                                    return (
                                        <li key={`cd_${idx}`} className="item">
                                            {/* Content List */}
                                            <div className="content-list d-flex align-items-center">
                                                <div className="content-icon">
                                                <span className={item.featured}>
                                                    <i className={item.icon} />
                                                </span>
                                                </div>
                                                <div className="content-body ml-4">
                                                <h3 className="m-0">{item.title}</h3>
                                                <p className="mt-3" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="wrapper-animation d-none d-md-block">
                                <div className="container-animation">
                                    <div className="square-animation">
                                        <a className="navbar-brand" href="/"><img alt="" src="/img/base.png" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;