// ABIs
import abiERC20 from "./abiERC20.json";
import abiIndexUtils from "./abiIndexUtils.json";
import abiWeightedIndex from "./abiWeightedIndex.json";
import abiUniswapV2Pair from "./abiUniswapV2Pair.json";
import abiTokenRewards from "./abiTokenRewards.json";
import abiStakingPoolToken from "./abiStakingPoolToken.json";

export { abiERC20, abiIndexUtils, abiWeightedIndex, abiUniswapV2Pair, abiTokenRewards, abiStakingPoolToken };

const useGoerli = process.env.REACT_APP_USE_GOERLI === "true";
const useSepolia = process.env.REACT_APP_USE_SEPOLIA === "true";

// HIVE address
let addressHive; 

if (useGoerli) {
    addressHive = "0xe6324eFa4253Ea5eF3214944901f501e4c3130e6";
} else if (useSepolia) {
    addressHive = "0x12499eDfe526d73086228b7e03101A43E5547212";
} else {
    addressHive = "0x5399d094b5eC64bDeeE130206098EF0e385Dc2e9";
}

export { addressHive };

// HIVE configuration
export const configHive = {
    address: addressHive,
    abi: abiERC20,
};

// cHIVE address
let addressCHive;

if (useGoerli) {
    addressCHive = "0xf8CAd9731fc460b333e583605d02A65b57CF45a9";
} else if (useSepolia) {
    addressCHive = "0x67f73b22C4d2248194064EDC41891538f4849F86";
} else {
    addressCHive = "0x0000000000000000000000000000000000000000";
}

export { addressCHive };

// cHIVE configuration
export const configCHive = {
    address: addressCHive,
    abi: abiWeightedIndex,
};

// USDC address
let addressUSDC;

if (useGoerli) {
    addressUSDC = "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C";
} else if (useSepolia) {
    addressUSDC = "0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8";
} else {
    addressUSDC = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
}

export { addressUSDC };

// USDC configuration
export const configUSDC = {
    address: addressUSDC,
    abi: abiERC20,
};

// IndexUtils address
let addressIndexUtils;

if (useGoerli) {
    addressIndexUtils = "0x06ba02391fe99fd2ebc1ed0867d2c6dd20412622";
} else if (useSepolia) {
    addressIndexUtils = "0x56b9bC00a01364583c3D525226570dD81102d455";
} else {
    addressIndexUtils = "0x648B71BaBb3b356812E12054D91B0B1C627f8988";
}

export { addressIndexUtils };

// IndexUtils configuration
export const configIndexUtils = {
    address: addressIndexUtils,
    abi: abiIndexUtils,
};

// TokenRewards address
let addressTokenRewards;

if (useGoerli) {
    addressTokenRewards = "";
} else if (useSepolia) {
    addressTokenRewards = "0x453d6ab6434b2103060edc598BbbF908c52EC0Bc";
} else {
    addressTokenRewards = "0x590Ac56575E04f656461B9cE36351eBD29221e86";
}

export { addressTokenRewards };

// TokenRewards configuration
export const configTokenRewards = {
    address: addressTokenRewards,
    abi: abiTokenRewards,
};

// StakingPoolToken address
let addressStakingPoolToken;

if (useGoerli) {
    addressStakingPoolToken = "";
} else if (useSepolia) {
    addressStakingPoolToken = "0xE57575321CD4A4CC709E1A9EC91626cc034cc63e";
} else {
    addressStakingPoolToken = "0xFb21e32f3c41225A043FEcC5AcA553801a0bB26D";
}

export { addressStakingPoolToken };

// StakingPoolToken configuration
export const configStakingPoolToken = {
    address: addressStakingPoolToken,
    abi: abiStakingPoolToken,
};