import React, { Component } from 'react';

class Cta extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        const jsonData = {
            "img": "/img/hive-protocol.png",
            "title": "The Hive Philosophy",
            "content": "One of the driving forces of the cryptocurrency market is constant price fluctuations. Simple, secure and decentralized investment tools should be accessible to everyone in order to capitalize on volatility without the stress of constant chart monitoring. The goal is to constantly adapt to market conditions so that Hive’s community can keep thriving in the long term.",
            "btn": "Arbitrage Now",
            "btnIcon": "icon-rocket mr-2"
        };

        this.setState({
            data: jsonData
        });
    }
    render() {
        return (
            <section className="cta-area p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 card">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-md-5 text-center">
                                    <img src={this.state.data.img} alt="" />
                                </div>
                                <div className="col-12 col-md-6 mt-4 mt-md-0">
                                    <h2 className="m-0">{this.state.data.title}</h2>
                                    <p>{this.state.data.content}</p>
                                    {/*<div className="cta-action">
                                        <a className="btn btn-bordered active d-inline-block" href="/hip-cell"><i className={this.state.data.btnIcon} />{this.state.data.btn}</a>
                                    </div>*/}
                                </div>
                            </div>
                            <a className="cta-link" href="/hip-cell" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cta;