import React, { useEffect, useState } from "react";
import { useAccountModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

const ConnectWalletButton = () => {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();

  const { address: addressData, isConnected } = useAccount();

  const [walletAddress, setWalletAddress] = useState("");
  const [shortWalletAddress, setShortWalletAddress] = useState("");

  useEffect(() => {
    if (isConnected) {
      let first = addressData.slice(0, 6);
      let last = addressData.slice(-4);
      setWalletAddress(first + "..." + last);
    }
  }, [isConnected, addressData]);

  return (
    <>
      {openConnectModal && (
        <button className="btn ml-lg-auto btn-bordered-white" onClick={openConnectModal}><i className="icon-wallet mr-md-2" />Wallet Connect</button>
      )}

      {openAccountModal && (
        <button href="/wallet-connect" className="btn ml-lg-auto btn-bordered-white" onClick={openAccountModal}><i className="icon-wallet mr-md-2" />{walletAddress}</button>
      )}
    </>
  );
};

export default ConnectWalletButton;
