import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import CellComponent from '../components/Cell/Cell';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';

class Cell extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Breadcrumb title="HIP Cell (cHIP)" subpage="Cells" page="HIP Cell (cHIP)" />
                <CellComponent />
                <Footer />
                <ModalSearch />
                <ModalMenu />
            </div>
        );
    }
}

export default Cell;