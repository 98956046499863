import React from 'react';

import Rainbowkit from "./Rainbowkit.jsx";

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes';

function App() {
  return (
    <div>
      <Rainbowkit>
        <MyRouts />
      </Rainbowkit>
    </div>
  );
}

export default App;