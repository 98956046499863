import React, { Component, useState } from 'react';
import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const initData = {
    sub_heading: "Liquid Assets",
    heading: "Cells",
    btn: "View All",
    actionBtn: "Load More"
}

const data = [
    {
        id: "1",
        img: "/img/hive-protocol.png",
        blockchain: "/img/base-small.png",
        title: "Hive Protocol",
        price: "<span>HIP $0.89</span> <span>:</span> <span>cHIP $0.89</span>",
        tvl: "$1,000,000",
        rewards: "$196,666",
        apr: "100%"
    },
    {
        id: "2",
        img: "/img/eth-glyph-colored.434446fa.png",
        blockchain: "/img/base-small.png",
        title: "Ethereum",
        price: "<span>ETH $3507.53</span> <span>:</span> <span>cETH $3517.80</span>",
        tvl: "$103,872",
        rewards: "$5,038",
        apr: "65.8%"
    },
    {
        id: "3",
        img: "/img/bitcoin.png",
        blockchain: "/img/base-small.png",
        title: "Bitcoin",
        price: "<span>BTC $67,104.41</span> <span>:</span> <span>cBTC $67,103.88</span>",
        tvl: "$82,112",
        rewards: "$3,460",
        apr: "20.5%"
    },
    {
        id: "4",
        img: "/img/solana.png",
        blockchain: "/img/base-small.png",
        title: "Solana",
        price: "<span>SOL $198.14</span> <span>:</span> <span>cSOL $199.01</span>",
        tvl: "$17,646",
        rewards: "$8,923",
        apr: "84%"
    },
    {
        id: "5",
        img: "/img/tether.png",
        blockchain: "/img/base-small.png",
        title: "Tether",
        price: "<span>USDT $0.99</span> <span>:</span> <span>cUSDT $1.00</span>",
        tvl: "$33,334",
        rewards: "$1018",
        apr: "14%"
    }
]

class ProjectTwo extends Component {
    state = {
        initData: {},
        data: [],
        fairValue: "...",
        dexValue: "...",
        tvl: "...",
        apr: "...",
        totalRewards: "..."
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        });

        const apiURL = backendURL + '/0x8df46219cd06c9728bd354794922ccb962bc9a82';
        axios.get(apiURL)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        fairValue: `$${res.data.result.fairValue}`,
                        dexValue: `$${res.data.result.dexValue}`,
                        tvl: new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 0
                        }).format(res.data.result.tvl),
                        apr: `${parseFloat(res.data.result.apr).toFixed(2)}%`,
                        totalRewards: new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 0
                        }).format(res.data.result.totalRewards)
                    }, () => {
                        const updatedData = this.state.data.map((item, index) => {
                            if (index === 0) {
                                return {
                                    ...item,
                                    price: `<span>HIP ${this.state.fairValue}</span> <span>:</span> <span>cHIP ${this.state.dexValue}</span>`,
                                    tvl: `${this.state.tvl}`,
                                    rewards: `${this.state.totalRewards}`,
                                    apr: `${this.state.apr}`
                                };
                            }
                            return item;
                        });

                        this.setState({
                            data: updatedData
                        });
                    });
                }
            })
            .catch(err => console.log(err));
    }
    render() {
        return (
            <section className="explore-area prev-project-area load-more p-0" id="cells">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end m-0">
                            <div className="intro-content">
                                <span className="intro-text">{this.state.initData.sub_heading}</span>
                                <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                            </div>
                            {/* <div className="intro-btn">
                                <a className="btn content-btn" href="/project-four">{this.state.initData.btn}</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        {this.state.data.map((item, idx) => {
                            return (
                                <div key={`pdt_${idx}`} className="col-12 item">
                                    <div className="card project-card prev-project-card">
                                        <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                                            <div className="item-header d-flex align-items-center mb-4 mb-md-0">
                                                <img className="card-img-top avatar-max-lg" src={item.img} alt="" />
                                                <div className="content ml-4">
                                                    <h4 className="m-0">{item.title}</h4>
                                                    <h6 className="mt-3 mb-0" dangerouslySetInnerHTML={{ __html: item.price }}></h6>
                                                </div>
                                            </div>
                                            <div className="blockchain cell-info mr-1 mr-md-0">
                                                <img src={item.blockchain} alt="" />
                                            </div>
                                            <div className="cell-info item-tvl">
                                                <div className="single-item">
                                                    <span>TVL: </span>
                                                    <span>{item.tvl}</span>
                                                </div>
                                            </div>
                                            <div className="cell-info item-rewards">
                                                <div className="single-item d-none d-md-inline-block">
                                                    <span>Rewards: </span>
                                                    <span>{item.rewards}</span>
                                                </div>
                                            </div>
                                            <div className="cell-info item-apr">
                                                <div className="single-item">
                                                    <span>APR: </span>
                                                    <span>{item.apr}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {idx !== 0 && (
                                            <div>
                                                <div className="project-coming-soon"></div>
                                                <div className="coming-soon-mention">
                                                    <div className="coming-soon-label">Coming Soon</div>
                                                </div>
                                            </div>
                                        )}
                                        <a className="project-link" href="/hip-cell" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <a id="load-btn" className="btn btn-bordered-white mt-4 mt-md-5" href="#">{this.state.initData.actionBtn}</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ProjectTwo;