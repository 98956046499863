import React, {
    useState,
    useEffect
} from 'react';
import axios from 'axios';

import {
    useAccount,
    useContractRead,
    usePrepareContractWrite,
    useContractWrite,
    useBalance,
    useWaitForTransaction
} from "wagmi";

import {
    FixedNumber,
    ethers
} from 'ethers';

import {
    addressHive,
    configHive,
    addressCHive,
    configCHive,
    addressUSDC,
    configUSDC,
    addressIndexUtils,
    configIndexUtils,
    abiUniswapV2Pair,
    configTokenRewards,
    configStakingPoolToken
} from "../../contract/config";

import { IMaskInput } from 'react-imask';
import Decimal from 'decimal.js';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const Cell = () => {
    let [balanceWrap, setBalanceWrap] = useState(0);
    let [amountWrap, setAmountWrap] = useState(0);
    let [allowanceWrap, setAllowanceWrap] = useState(0);
    let [allowedWrap, setAllowedWrap] = useState(0);

    let [balanceUnwrap, setBalanceUnwrap] = useState(0);
    let [amountUnwrap, setAmountUnwrap] = useState(0);
    let [allowedUnwrap, setAllowedUnwrap] = useState(0);

    let [balancePaired, setBalancePaired] = useState(0);
    let [amountCell, _setAmountCell] = useState(0);
    let [amountPaired, _setAmountPaired] = useState(0);
    let [allowanceCell, setAllowanceCell] = useState(0);
    let [allowedCell, setAllowedCell] = useState(0);
    let [approvedCell, setApprovedCell] = useState(false);
    let [allowancePaired, setAllowancePaired] = useState(0);
    let [allowedPaired, setAllowedPaired] = useState(0);
    let [approvedPaired, setApprovedPaired] = useState(false);

    let [pendingRewards, _setPendingRewards] = useState(0);
    let [ownedShares, _setOwnedShares] = useState(0);

    let [willStake, setWillStake] = useState(true);
    const [deadline, setDeadline] = useState(Math.floor(Date.now() / 1000) + 20 * 60);

    const {
        address,
        isConnected
    } = useAccount();

    const fullAddress = '0x0000000000000000000000000000000000000000';
    const [shortAddress, setShortAddress] = useState("");
    const [fairValue, _setFairValue] = useState("...");
    const [dexValue, _setDexValue] = useState("...");
    const [tvl, _setTvl] = useState("...");
    const [lpTvl, _setLpTvl] = useState("...");
    const [apr, _setApr] = useState("...");
    const [cbr, _setCbr] = useState("...");
    const [pairedLpToken, setPairedLpToken] = useState("...");
    const [shortPairedLpToken, setShortPairedLpToken] = useState("...");
    const [partnerAddress, setPartnerAddress] = useState("...");
    const [shortPartnerAddress, setShortPartnerAddress] = useState("...");
    const [totalRewardsToken, _setTotalRewardsToken] = useState("...");
    const [totalRewards, _setTotalRewards] = useState("...");
    const [dexAddress, setDexAddress] = useState("");

    const setFairValue = (value) => {
        _setFairValue(`$${value}`);
    };

    const setDexValue = (value) => {
        _setDexValue(`$${value}`);
    };

    const setTvl = (value) => {
        _setTvl(new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(value));
    };

    const setLpTvl = (value) => {
        _setLpTvl(new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(value));
    };

    const setApr = (value) => {
        _setApr(parseFloat(value).toFixed(2) + '%');
    };

    const setCbr = (value) => {
        _setCbr(parseFloat(value).toFixed(4));
    };

    const setTotalRewardsToken = (value) => {
        const roundedValue = Math.round(value);
        _setTotalRewardsToken(new Intl.NumberFormat('en-US').format(roundedValue));
    };

    const setTotalRewards = (value) => {
        _setTotalRewards(new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        }).format(value));
    };

    const setAmountCell = (value) => {
        _setAmountCell(value);
    };

    const setAmountCellProportion = (value) => {
        if (typeof(pairReserves) !== 'undefined') {
            let reserve0 = ethers.formatUnits(pairReserves[0].toString(), decimalsUSDC);
            let reserve1 = ethers.formatUnits(pairReserves[1].toString(), decimalsCHive);

            console.log(reserve0);
            console.log(reserve1);

            let ratio = new Decimal(reserve1).dividedBy(new Decimal(reserve0));
            let newValue = new Decimal(value).dividedBy(ratio);
            let roundedValue = newValue.toFixed(decimalsUSDC);

            if (new Decimal(roundedValue).gt(balanceUSDC.formatted)) {
                setMaxPaired();
            } else {
                _setAmountPaired(roundedValue.toString());
            }
        }
    };

    const setAmountPaired = (value) => {
        _setAmountPaired(value);
    };

    const setAmountPairedProportion = (value) => {
        if (typeof(pairReserves) !== 'undefined') {
            let reserve0 = ethers.formatUnits(pairReserves[0].toString(), decimalsUSDC);
            let reserve1 = ethers.formatUnits(pairReserves[1].toString(), decimalsCHive);

            let ratio = new Decimal(reserve1).dividedBy(new Decimal(reserve0));
            let newValue = new Decimal(value).times(ratio);
            let roundedValue = newValue.toFixed(decimalsCHive);

            if (new Decimal(roundedValue).gt(balanceCHive.formatted)) {
                setMaxCell();
            } else {
                _setAmountCell(roundedValue.toString());
            }
        }
    };

    const setPendingRewards = (value) => {
        let rewards = ethers.formatUnits(unpaidRewards.toString(), decimalsHive);
        //console.log(rewards);
        _setPendingRewards(rewards);
    };

    const setOwnedShares = (value) => {
        let amountShares = ethers.formatUnits(shares.toString(), 18);
        amountShares = Number(new Decimal(amountShares).toFixed(9))
        //console.log(amountShares);
        _setOwnedShares(amountShares);
    };

    const [refreshKey, setRefreshKey] = useState(0);

    const refresh = () => {
        setRefreshKey(refreshKey => refreshKey + 1);
    };

    // HIVE

    const {
        data: balanceHive
    } = useBalance({
        address: address,
        token: addressHive,
        watch: true
    });

    const {
        data: allowanceHive
    } = useContractRead({
        ...configHive,
        functionName: "allowance",
        args: [address, addressIndexUtils],
        watch: true
    });

    const {
        data: decimalsHive
    } = useContractRead({
        ...configHive,
        functionName: "decimals",
    });

    // cHIVE

    const {
        data: balanceCHive
    } = useBalance({
        address: address,
        token: addressCHive,
        watch: true
    });

    const {
        data: allowanceCHive
    } = useContractRead({
        ...configCHive,
        functionName: "allowance",
        args: [address, addressIndexUtils],
        watch: true
    });

    const {
        data: decimalsCHive
    } = useContractRead({
        ...configCHive,
        functionName: "decimals",
    });

    // USDC

    const {
        data: balanceUSDC
    } = useBalance({
        address: address,
        token: addressUSDC,
        watch: true
    });

    const {
        data: allowanceUSDC
    } = useContractRead({
        ...configUSDC,
        functionName: "allowance",
        args: [address, addressIndexUtils],
        watch: true
    });

    const {
        data: decimalsUSDC
    } = useContractRead({
        ...configUSDC,
        functionName: "decimals",
    });

    //scHive

    const {
        data: allowanceSCHive
    } = useContractRead({
        ...configStakingPoolToken,
        functionName: "allowance",
        args: [address, addressIndexUtils],
        watch: true
    });

    // Wrap

    const {
        config: configWrap
    } = usePrepareContractWrite({
        ...configIndexUtils,
        functionName: "bond",
        args: [
            addressCHive,
            addressHive,
            allowedWrap,
            allowedWrap
        ],
    });

    const {
        data: dataWrap,
        write: writeWrap
    } = useContractWrite({
        ...configWrap,
        onSuccess: () => {
            console.log('Wrap: Transaction has been sent and confirmed');
        },
        onError: (error) => {
            console.log('Wrap: Transaction has been rejected', error);
        }
    });

    useWaitForTransaction({
        confirmations: 1,
        hash: dataWrap?.hash,
        onSuccess() {
            console.log('Wrap: Refreshing balances');
            refresh();
        },
    });

    const {
        config: configWrapApprove
    } = usePrepareContractWrite({
        ...configHive,
        functionName: "approve",
        args: [
            addressIndexUtils,
            ethers.MaxUint256
        ],
    });

    const {
        write: writeApproveWrap
    } = useContractWrite(configWrapApprove);

    // Unwrap

    const {
        config: configUnwrap
    } = usePrepareContractWrite({
        ...configCHive,
        functionName: "debond",
        args: [
            allowedUnwrap,
            [],
            []
        ],
    });

    const {
        write: writeUnwrap,
        data: dataUnwrap
    } = useContractWrite(configUnwrap);

    useWaitForTransaction({
        confirmations: 1,
        hash: dataUnwrap?.hash,
        onSuccess() {
            console.log('Unwrap: Refreshing balances');
            refresh();
        },
    });

    // Provide LP + Stake

    const {
        data: pairReserves
    } = useContractRead({
        address: dexAddress,
        abi: abiUniswapV2Pair,
        functionName: "getReserves",
        watch: true
    });

    const {
        write: writeProvideLPStake,
        data: dataProvideLPStake
    } = useContractWrite({
        ...configIndexUtils,
        functionName: "addLPAndStake",
        args: [
            addressCHive,
            allowedCell,
            addressUSDC,
            allowedPaired,
            0,
            1000,
            deadline
        ],
    });

    useWaitForTransaction({
        confirmations: 1,
        hash: dataProvideLPStake?.hash,
        onSuccess() {
            console.log('LP + Stake: Refreshing balances');
            refresh();
        },
    });

    const {
        config: configCellApprove
    } = usePrepareContractWrite({
        ...configCHive,
        functionName: "approve",
        args: [
            addressIndexUtils,
            ethers.MaxUint256
        ],
    });

    const {
        write: writeApproveCell
    } = useContractWrite(configCellApprove);

    const {
        config: configPairedApprove
    } = usePrepareContractWrite({
        ...configUSDC,
        functionName: "approve",
        args: [
            addressIndexUtils,
            ethers.MaxUint256
        ],
    });

    const {
        write: writeApprovePaired
    } = useContractWrite(configPairedApprove);

    // Claim Rewards

    const {
        data: unpaidRewards
    } = useContractRead({
        ...configTokenRewards,
        functionName: "getUnpaid",
        args: [address],
        watch: true
    });

    const {
        config: configClaimRewards
    } = usePrepareContractWrite({
        ...configTokenRewards,
        functionName: "claimReward",
        args: [address],
    });

    const {
        write: writeClaimRewards
    } = useContractWrite(configClaimRewards);

    const {
        data: shares
    } = useContractRead({
        ...configTokenRewards,
        functionName: "shares",
        args: [address],
        watch: true
    });

    // Unstake

    const {
        write: writeApproveUnstake,
        data: dataApproveUnstake,
    } = useContractWrite({
        ...configStakingPoolToken,
        functionName: "approve",
        args: [
            addressIndexUtils,
            ethers.MaxUint256
        ],
        onSuccess: () => {
            console.log('Approve Unstake: Transaction has been sent and confirmed');
        },
        onError: (error) => {
            console.log('Approve Unstake: Transaction has been rejected', error);
            setIsLoadingUnstake(false);
        }
    });

    useWaitForTransaction({
        confirmations: 1,
        hash: dataApproveUnstake?.hash,
        onSuccess() {
            console.log('Approve Unstake: Refreshing balances');
            setCanUnstake(true);
            setIsLoadingUnstake(false);
            refresh();
        },
    });

    const {
        write: writeUnstakeAndRemoveLP,
        data: dataUnstakeAndRemoveLP,
    } = useContractWrite({
        ...configIndexUtils,
        functionName: "unstakeAndRemoveLP",
        args: [
            addressCHive,
            shares,
            0,
            0,
            deadline
        ],
        onSuccess: () => {
            console.log('Unstake: Transaction has been sent and confirmed');
        },
        onError: (error) => {
            console.log('Unstake: Transaction has been rejected', error);
            setIsLoadingUnstake(false);
        }
    });

    useWaitForTransaction({
        confirmations: 1,
        hash: dataUnstakeAndRemoveLP?.hash,
        onSuccess() {
            console.log('Unstake: Refreshing balances');

            _setOwnedShares(0);

            // Close modal
            let closeButton = document.querySelector('#unstakeModal .close');
            closeButton && closeButton.click();

            setIsLoadingUnstake(false);
            refresh();
        },
    });

    // Interface

    const setMaxWrap = () => {
        amountWrap = balanceWrap;
        setAmountWrap(amountWrap.toString());
    };

    const setMaxUnwrap = () => {
        amountUnwrap = balanceUnwrap;
        setAmountUnwrap(amountUnwrap.toString());
    };

    const setMaxCell = () => {
        amountCell = balanceUnwrap;
        setAmountCell(amountCell.toString());
        setAmountCellProportion(amountCell.toString());
    };

    const setMaxPaired = () => {
        amountPaired = balancePaired;
        setAmountPaired(amountPaired.toString());
        setAmountPairedProportion(amountPaired.toString());
    };

    const [isWrapApproveDisabled, setWrapApproveDisabled] = useState(false);
    const [isWrapButtonDisabled, setWrapButtonDisabled] = useState(false);

    const [isUnwrapButtonDisabled, setUnwrapButtonDisabled] = useState(false);

    const [isLpApproveCellDisabled, setLpApproveCellDisabled] = useState(false);
    const [isLpApprovePairedDisabled, setLpApprovePairedDisabled] = useState(false);
    const [isLpButtonDisabled, setLpButtonDisabled] = useState(false);

    const [isLoadingUnstake, setIsLoadingUnstake] = useState(false);
    const [canUnstake, setCanUnstake] = useState(false);

    const approveWrap = () => {
        writeApproveWrap?.();
    }

    const wrap = () => {
        setAmountWrap(0);
        writeWrap?.();
    };

    const unwrap = () => {
        setAmountUnwrap(0);
        writeUnwrap?.();
    };

    const approveCell = () => {
        writeApproveCell?.();
    };

    const approvePaired = () => {
        writeApprovePaired?.();
    };

    const provideLPStake = () => {
        _setAmountCell(0);
        _setAmountPaired(0);
        //console.log(allowedCell);
        //console.log(allowedPaired);
        writeProvideLPStake?.();
    };

    const claimRewards = () => {
        writeClaimRewards?.();
    };

    const approveUnstake = () => {
        setIsLoadingUnstake(true);
        writeApproveUnstake?.();
    };

    const unstake = () => {
        setIsLoadingUnstake(true);
        writeUnstakeAndRemoveLP?.();
    };

    useEffect(() => {
        const apiURL = backendURL + '/0x0000000000000000000000000000000000000000';
        axios.get(apiURL)
            .then(res => {
                if (res.data.success) {
                    setFairValue(res.data.result.fairValue);
                    setDexValue(res.data.result.dexValue);
                    setTvl(res.data.result.tvl);
                    setLpTvl(res.data.result.lpTvl);
                    setApr(res.data.result.apr);
                    setCbr(res.data.result.cbr);
                    setPairedLpToken(res.data.result.pairedLpToken);
                    setShortPairedLpToken(`${res.data.result.pairedLpToken.slice(0,6)}...${res.data.result.pairedLpToken.slice(-4)}`);
                    setPartnerAddress(res.data.result.partnerAddress);
                    setShortPartnerAddress(`${res.data.result.partnerAddress.slice(0,6)}...${res.data.result.partnerAddress.slice(-4)}`);
                    setTotalRewardsToken(res.data.result.totalRewardsToken);
                    setTotalRewards(res.data.result.totalRewards);
                    setDexAddress(res.data.result.dexAddress);
                    //setDexAddress('0x61Cc96319D284e171f2DFb0c40b7E2e8433fD087');
                  }
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        //console.log(addressCHive);

        if (isConnected) {
            if (balanceHive) {
                setBalanceWrap(balanceHive.formatted);
            }

            if (balanceCHive && (balanceCHive.formatted > 0)) {
                setBalanceUnwrap(balanceCHive.formatted);
                setUnwrapButtonDisabled(false);
            } else {
                setUnwrapButtonDisabled(true);
            }

            if (balanceUSDC) {
                setBalancePaired(balanceUSDC.formatted);
            }

            if (allowanceHive) {
                setAllowanceWrap(allowanceHive.toString());

                let amountWrapUnits = ethers.parseUnits(amountWrap.toString(), decimalsHive);

                if (allowanceWrap < amountWrapUnits) {
                    setAllowedWrap(allowanceWrap);
                    setWrapButtonDisabled(true);
                    setWrapApproveDisabled(false);
                } else {
                    setAllowedWrap(amountWrapUnits);
                    setWrapButtonDisabled(false);
                    setWrapApproveDisabled(true);
                }
            } else {
                setWrapButtonDisabled(true);

                if (balanceHive && (balanceHive.formatted > 0)) {
                    setWrapApproveDisabled(false);
                } else {
                    setWrapApproveDisabled(true);
                }
            }

            setAllowedUnwrap(ethers.parseUnits(amountUnwrap.toString(), decimalsCHive));

            if (allowanceCHive) {
                //console.log(allowanceCHive);

                setAllowanceCell(allowanceCHive.toString());

                let amountCellUnits = ethers.parseUnits(amountCell.toString(), decimalsCHive);

                if (allowanceCell < amountCellUnits) {
                    setAllowedCell(allowanceCell);
                    setApprovedCell(false);
                } else {
                    setAllowedCell(amountCellUnits);
                    setApprovedCell(true);
                }
            } else {
                setApprovedCell(false);
            }

            if (allowanceUSDC) {
                //console.log(allowanceUSDC);

                setAllowancePaired(allowanceUSDC.toString());

                let amountPairedUnits = ethers.parseUnits(amountPaired.toString(), decimalsUSDC);

                if (allowancePaired < amountPairedUnits) {
                    setAllowedPaired(allowancePaired);
                    setApprovedPaired(false);
                } else {
                    setAllowedPaired(amountPairedUnits);
                    setApprovedPaired(true);
                }
            } else {
                setApprovedPaired(false);
            }

            if (!approvedCell) {
                if (balanceCHive && (balanceCHive.formatted > 0)) {
                    setLpApproveCellDisabled(false);
                } else {
                    setLpApproveCellDisabled(true);
                }
            } else {
                setLpApproveCellDisabled(true);
            }

            if (!approvedPaired) {
                if (balanceUSDC && (balanceUSDC.formatted > 0)) {
                    setLpApprovePairedDisabled(false);
                } else{
                    setLpApprovePairedDisabled(true);
                }
            } else {
                setLpApprovePairedDisabled(true);
            }

            if (approvedCell && approvedPaired) {
                setLpButtonDisabled(false);
            } else {
                setLpButtonDisabled(true);
            }

            if (unpaidRewards) {
                setPendingRewards(unpaidRewards.formatted);
            }

            if (shares) {
                //console.log(shares);
                setOwnedShares(shares.formatted);
            }
            
            if (allowanceSCHive > shares) {
                setCanUnstake(true);
            }
        }
    }, [
        isConnected,
        balanceHive,
        balanceCHive,
        balanceUSDC,
        allowanceHive,
        amountWrap,
        allowanceCHive,
        amountUnwrap,
        amountCell,
        allowanceUSDC,
        amountPaired,
        approvedCell,
        approvedPaired,
        refreshKey,
        unpaidRewards,
        pendingRewards,
        shares,
        ownedShares,
        balanceWrap,
        balanceUnwrap,
        balancePaired,
    ]);

    return (
        <section className="staking-area">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="card no-hover staking-card single-staking">
                            <a href={`https://basescan.org/address/${fullAddress}`} target="_blank">
                                <h3 className="m-0">HIP Cell (cHIP)</h3>
                                <span className="contract">{fullAddress}</span>
                            </a>
                            <div className="staking-tab-content mt-3">
                                <div className="info-box d-flex justify-content-between">
                                    <div className="info-left">
                                        <ul className="list-unstyled">
                                            <li><strong>Annual Percentage Rate (APR):</strong> {apr}</li>
                                            <li><strong>Collateral Ratio:</strong> {cbr}</li>
                                            <li><strong>LP Total Value Locked:</strong> {lpTvl}</li>
                                            <li><strong>Paired LP Token:</strong> {shortPairedLpToken}</li>
                                        </ul>
                                    </div>
                                    {/*
                                        <div className="info-right d-flex flex-column"><span>0%</span><span>APR</span></div>
                                    */}
                                </div>
                            </div>
                            <div className="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                                <strong>Important!</strong> If HIP, cHIP, or USDC balances are frozen after you successfully send a transaction with your wallet, please refresh the page to see the updated balances.
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <ul className="nav nav-tabs staking-tabs border-0 my-3 my-md-4" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation"><a className="tab-link active" id="tab-one-tab" data-toggle="tab" href="#tab-one" role="tab" aria-selected="true">Wrap</a></li>
                                <li className="nav-item" role="presentation"><a className="tab-link" id="tab-two-tab" data-toggle="tab" href="#tab-two" role="tab" aria-selected="true">Unwrap</a></li>
                                <li className="nav-item" role="presentation"><a className="tab-link" id="tab-three-tab" data-toggle="tab" href="#tab-three" role="tab" aria-selected="true">LP + Stake</a></li>
                                <li className="nav-item" role="presentation"><a className="tab-link" id="tab-four-tab" data-toggle="tab" href="#tab-four" role="tab" aria-selected="true">Yield + Unstake</a></li>
                            </ul>
                            <div className="tab-content mt-md-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="tab-one" role="tabpanel">
                                    <div className="staking-tab-content">
                                        <div className="input-box my-2">
                                            <div className="row balance-container">
                                                <div className="col-4">Balance</div>
                                                <div className="col-8">{balanceWrap} HIP</div>
                                            </div>
                                            <div className="input-area d-flex flex-column flex-md-row mb-3">
                                                <div className="input-text">
                                                    <IMaskInput
                                                        type="text"
                                                        mask={Number}
                                                        radix="."
                                                        scale={decimalsHive}
                                                        value={amountWrap.toString()}
                                                        onAccept={(value) => setAmountWrap(value === '' ? 0 : parseFloat(value))}
                                                        min={0}
                                                        max={balanceWrap ? Number(new Decimal(balanceWrap).toFixed(decimalsHive)): 0}
                                                    />
                                                    <a href="#" onClick={(event) => { event.preventDefault(); setMaxWrap(); }}>Max</a>
                                                </div>
                                                <button className="btn input-btn mt-2 mt-md-0 ml-md-3" disabled={isWrapApproveDisabled} onClick={() => approveWrap()}>Approve</button>
                                            </div>
                                        </div>
                                        <div className="staking-actions my-2">
                                            <button className="btn input-btn" disabled={isWrapButtonDisabled} onClick={() => wrap()}>Wrap</button>
                                        </div>
                                        <span className="fees">This cell has a 1.5% wrap fee.</span>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-two" role="tabpanel">
                                    <div className="staking-tab-content">
                                        <div className="input-box my-2">
                                            <div className="row balance-container">
                                                <div className="col-4">Balance</div>
                                                <div className="col-8">{balanceUnwrap} cHIP</div>
                                            </div>
                                            <div className="input-area d-flex flex-column flex-md-row mb-3">
                                                <div className="input-text">
                                                    <IMaskInput
                                                        type="text"
                                                        mask={Number}
                                                        radix="."
                                                        scale={decimalsCHive}
                                                        value={amountUnwrap.toString()}
                                                        onAccept={(value) => setAmountUnwrap(value === '' ? 0 : new Decimal(value).toFixed(decimalsCHive))}
                                                        min={0}
                                                        max={balanceUnwrap ? Number(new Decimal(balanceUnwrap).toFixed(decimalsCHive)): 0}
                                                    />
                                                    <a href="#" onClick={(event) => { event.preventDefault(); setMaxUnwrap(); }}>Max</a>
                                                </div>
                                                <button className="btn input-btn mt-2 mt-md-0 ml-md-3" disabled>Approve</button>
                                            </div>
                                        </div>
                                        <div className="staking-actions my-2">
                                            <button className="btn input-btn" disabled={isUnwrapButtonDisabled} onClick={() => unwrap()}>Unwrap</button>
                                        </div>
                                        <span className="fees">This cell has a 0.9% unwrap fee.</span>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-three" role="tabpanel">
                                    <div className="staking-tab-content">
                                        <div className="input-box my-2">
                                            <div className="row balance-container">
                                                <div className="col-4">Balance</div>
                                                <div className="col-8">{balanceUnwrap} cHIP</div>
                                            </div>
                                            <div className="input-area d-flex flex-column flex-md-row mb-3">
                                                <div className="input-text">
                                                    <IMaskInput
                                                        className="input_number"
                                                        type="text"
                                                        mask={Number}
                                                        radix="."
                                                        scale={decimalsCHive}
                                                        value={amountCell.toString()}
                                                        onAccept={(value) => setAmountCell(value === '' ? 0 : new Decimal(value).toFixed(decimalsCHive))}
                                                        min={0}
                                                        max={balanceUnwrap ? Number(new Decimal(balanceUnwrap).toFixed(decimalsCHive)): 0}
                                                        onBlur={(e) => setAmountCellProportion(e.target.value)}
                                                    /> 
                                                    <a href="#" onClick={(event) => { event.preventDefault(); setMaxCell(); }}>Max</a>
                                                </div>
                                                <button className="btn input-btn mt-2 mt-md-0 ml-md-3" disabled={isLpApproveCellDisabled} onClick={() => approveCell()}>Approve</button>
                                            </div>
                                        </div>
                                        <div className="input-box my-2">
                                            <div className="row balance-container">
                                                <div className="col-4">Balance</div>
                                                <div className="col-8">{balancePaired} USDC</div>
                                            </div>
                                            <div className="input-area d-flex flex-column flex-md-row mb-3">
                                                <div className="input-text">
                                                    <IMaskInput
                                                        className="input_number"
                                                        type="text"
                                                        mask={Number}
                                                        radix="."
                                                        scale={decimalsUSDC}
                                                        value={amountPaired.toString()}
                                                        onAccept={(value) => setAmountPaired(value === '' ? 0 : value)}
                                                        min={0}
                                                        max={balancePaired ? Number(new Decimal(balancePaired).toFixed(decimalsUSDC)): 0}
                                                        onBlur={(e) => setAmountPairedProportion(e.target.value)}
                                                    />
                                                    <a href="#" onClick={(event) => { event.preventDefault(); setMaxPaired(); }}>Max</a>
                                                </div>
                                                <button className="btn input-btn mt-2 mt-md-0 ml-md-3" disabled={isLpApprovePairedDisabled} onClick={() => approvePaired()}>Approve</button>
                                            </div>
                                        </div>
                                        <div className="staking-actions my-2">
                                            <button className="btn input-btn" disabled={isLpButtonDisabled} onClick={() => provideLPStake()}>Provide LP + Stake</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-four" role="tabpanel">
                                    <div className="staking-tab-content">
                                        <div className="row">
                                            <div className="col-12 col-md-6 single-staking-item input-box">
                                                <span className="item-title mb-2">Pending rewards</span>
                                                <div className="input-area d-flex flex-column">
                                                    <h4 className="price m-0">{pendingRewards} HIP</h4>
                                                    <span className="reward my-2">Rewards are added at a given interval, based on traded volume. The more shares you own, the more rewards you get over time.</span>
                                                    <button className="btn input-btn mt-2" onClick={() => claimRewards()}>Claim</button>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 single-staking-item input-box">
                                                <span className="item-title mb-2">Shares</span>
                                                <div className="input-area d-flex flex-column">
                                                    <h4 className="price m-0">{ownedShares}</h4>
                                                    {/*<span className="reward my-2">The more shares you own, the more rewards you get over time.</span>*/}
                                                    <span className="reward my-2">Unstaking will send LP tokens to your wallet. You have to use <a href="https://swapbased.finance" target="_blank">SwapBased</a> to convert them back to cHIP and USDC.</span>
                                                    <button className="btn input-btn mt-2" data-toggle="modal" data-target="#unstakeModal">Unstake</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="staking-items mt-4 mt-md-0">
                            <div className="card no-hover staking-card">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="m-0">{fairValue}</h3>
                                        </div>
                                        <div className="col">
                                            <h3 className="m-0">{dexValue}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>Cell Fair Value</p>
                                        </div>
                                        <div className="col">
                                            <p>Cell DEX Value</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card no-hover staking-card my-4">
                                <h3 className="m-0">{tvl}</h3>
                                <p>Total Value Locked</p>
                            </div>
                            <div className="card no-hover staking-card">
                                <h3 className="m-0">{totalRewardsToken} HIP <span className="monetary-value">({totalRewards})</span></h3>
                                <p>Total Rewards</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="unstakeModal" tabIndex="-1" role="dialog" aria-labelledby="unstakeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="unstakeModalLabel">Token Transfer Approval Needed</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            To receive your staked cHIP and USDC tokens, approval of your LP tokens to be spent by our utilities contract is required for direct transfer.
                        </div>
                        <div className="modal-footer">
                            <div className="modal-footer-content">
                                <div className={`modal-actions ${isLoadingUnstake ? 'd-none' : ''}`}>
                                {canUnstake ? (
                                    <>
                                        {/* Buttons to show when canUnstake is true */}
                                        <button type="button" className="btn input-btn" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn input-btn ml-2" onClick={() => unstake()}>Unstake</button>
                                    </>
                                ) : (
                                    <>
                                        {/* Buttons to show when canUnstake is false */}
                                        <button type="button" className="btn input-btn" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn input-btn ml-2" onClick={() => approveUnstake()}>Approve</button>
                                    </>
                                )}
                                </div>
                                <div className={`modal-loading ${isLoadingUnstake ? '' : 'd-none'}`}>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    <span className="loading-text">{canUnstake ? 'Unstaking. Please wait...' : 'Approving. Please wait...'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Cell;