import React, { Component } from 'react';
import axios from 'axios';

class Work extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount(){
        const jsonData =
        {
            "sub_heading": "Technology",
            "heading": "Unique Features",
            "workData": [
                {
                    "id": 1,
                    "icon": "icons icon-shield text-effect",
                    "title": "Contract Audit",
                    "content": "Coming soon: Our smart contract prioritizes security, safeguarding your funds with expert oversight."
                },
                {
                    "id": 2,
                    "icon": "icons icon-list text-effect",
                    "title": "Cell Insurance",
                    "content": "Coming soon: First ever DeFi protocol to offer insurance plans to protect users' assets (optional self-service)."
                },
                {
                    "id": 3,
                    "icon": "icons icon-lock text-effect",
                    "title": "WrapLock Technology",
                    "content": "Coming soon: Protect your pooled assets from depreciation using our innovative WrapLock technology."
                }
            ]
        };

        this.setState({
            data: jsonData,
            workData: jsonData.workData
        });
    }
    render() {
        return (
            <section className="work-area" id="technology">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end mb-4">
                                <div className="intro-content">
                                    <span className="intro-text">{this.state.data.sub_heading}</span>
                                    <h3 className="mt-3 mb-0">{this.state.data.heading}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        {this.state.workData.map((item, idx) => {
                            return (
                                <div key={`wd_${idx}`} className="col-12 col-sm-6 col-lg-4 item mt-0">
                                    {/* Single Work */}
                                    <div className="single-work">
                                        <i className={item.icon} />
                                        <h4>{item.title}</h4>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;